<template>
  <div class="menu-oblige">
    <div class="list-tabs">
      <v-tabs
        bg-color="#704ad1"
        :hide-slider="false"
        slider-color="#704ad1"
        v-model="model"
      >
        <v-tab v-if="checkPermission('GAPTH')">
          <router-link
            class="menu-item"
            to="/obligee/gestion-appels-paiement-obligee"
          >
            Gestions des appels à paiement
          </router-link>
        </v-tab>
      </v-tabs>
      <!-- <div
        class="menu-link"
        v-if="checkPermission('Suivi des paiement Obligé')"
      >
        <router-link class="menu-item" to="/obligee/suivi-paiement-obligee">
          Suivi des paiement Obligé
        </router-link>
      </div> -->

      <!-- <div class="menu-link" v-if="checkPermission('GOTHS')">
        <router-link class="menu-item" to="/obligee/statistic-oblige">
          Statistique
        </router-link>
      </div> -->
      <!-- <div class="notif-export-file" v-if="getShowNotifExportFile">
        Préparation du fichier en cours
      </div> -->
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      model: 0
    };
  },
  computed: {
    ...mapGetters(['getUserData', 'checkPermission', 'getShowNotifExportFile'])
  }
};
</script>

<style scoped lang="scss">
.menu-oblige {
  position: absolute;
  top: 11px;
  right: 0;
  left: 0px;
  height: 36px;
  // z-index: 999;
  background: #fff;
  border-top: outset;
  .list-tabs {
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    .v-tabs {
      width: max-content;
    }
    .v-application .deep-purple {
      background-color: #704ad1 !important;
      border-color: #704ad1 !important;
      width: 100%;
    }
  }
}

.v-application {
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 5px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    // line-height: 42px;
    // padding: 9px 24px 9px 23px;
    text-align: center;
    // text-transform: capitalize;
    &.router-link-exact-active {
      color: #704ad1 !important;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
      // line-height: 42px;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      text-decoration: none;
      // background-color: transparent;
      //  background-color: #e0cffbb8;
      background-color: #f1edfb;
    }
    &:focus {
    }
  }
}
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0px;
  padding: 0px;
}
</style>
<style lang="scss">
.v-tab--active {
  &:focus,
  &:hover {
    background-color: #8a2be21f;
    filter: none;
  }
}
// .v-tabs-bar {
//   border-radius: inherit;
//   height: 36px;
// }
</style>
